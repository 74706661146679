.token-directory-container {
    .toolbar {
        background-color: gray;
        width: 900px;
        height: 30px;
        float: right;
        margin-left: 20px;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.6);
    }

    .toolbar_divider {
        border-left: 1px solid #fff;
        height: 22px;
        margin: 4px 4px 4px 4px;
    }

    .toolbar_divider.left {
        float: left;
    }

    .toolbar_divider.right {
        float: right;
    }

    .save_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url('./images/save.png');
        background-size: contain;
        background-color: transparent;
        border: 0;
    }

    .save_button:focus {
        outline: none !important;
        border: none;
    }

    .save_button:disabled {
        opacity: 0.5;
    }

    .refresh_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url('./images/refresh.png');
        background-color: transparent;
        background-size: contain;
        border: 0;
    }

    .refresh_button:focus {
        outline: none !important;
        border: none;
    }

    .delete_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url('./images/delete.png');
        background-color: transparent;
        background-size: contain;
        border: 0;
    }

    .delete_button:focus {
        outline: none !important;
        border: none;
    }

    #token-list {
        padding-top: 40px;
        width: 200px;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.8);
        :after {
            clear: both;
            content: '';
            display: block;
        }
    }

    #token_pagination {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .token_pagination a,
    .token_pagination span {
        color: white;
        float: left;
        text-decoration: none;
        margin: 0px 3px 0px 3px;
    }

    .token_pagination a.active {
        color: cyan;
    }

    select.token_list {
        width: inherit;
        background-color: inherit;
        box-shadow: inherit;
        color: white;
        border-width: 0;
        padding: 0.4em;
        scrollbar-width: none;
        overflow: hidden;
        height: 690px;
    }

    select.token_list:focus {
        outline: none !important;
        border: none;
    }

    input[type='file'] {
        display: none;
    }

    .token_list_container {
        width: 900px;
        float: right;
    }

    .token_list {
        width: 900px;
        height: 100%;
        float: right;
        margin-left: 20px;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .token_info {
        margin-bottom: 15px;
        padding-bottom: 5px;
        width: 900px;
        height: 100%;
        float: right;
        margin-left: 20px;
        text-align: left;
        position: relative;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.5);
    }

    #create_token {
        margin-top: 2px;
        margin-bottom: 12px;
        float: left;
        width: 200px;
        margin-left: 2px;
    }

    #create_token_name {
        margin-top: 2px;
        float: left;
        width: 168px;
        margin-left: 2px;
    }

    #confirm_token_creation {
        height: 26px;
        margin-top: 2px;
        margin-left: 4px;
        width: 26px;
        float: left;
        background-image: url('./images/import-deck.png');
        background-size: contain;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    #confirm_token_creation.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }

    .token_texture_entry {
        height: fit-content;
        box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.5);
        margin-bottom: 10px;
        position: relative;
        display: inline-flex;
    }

    .token_textures {
        display: inline-block;
        margin-bottom: 5px;
    }

    .texture-entry {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
    }

    .texture {
        background-color: black;
        display: block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 10px auto;
    }

    .texture.missing {
        background-image: url('./images/missing.svg') !important;
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .texture.loading {
        background-color: transparent;
        position: relative;
    }

    .texture.loading div {
        position: absolute;
        top: calc(50% - 6.5px);
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .texture.loading div:nth-child(1) {
        left: calc(50% - 38px + 8px);
        animation: lds-ellipsis1 0.6s infinite;
    }

    .texture.loading div:nth-child(2) {
        left: calc(50% - 38px + 8px);
        animation: lds-ellipsis2 0.6s infinite;
    }

    .texture.loading div:nth-child(3) {
        left: calc(50% - 38px + 32px);
        animation: lds-ellipsis2 0.6s infinite;
    }

    .texture.loading div:nth-child(4) {
        left: calc(50% - 38px + 56px);
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }

    .texture-selection {
        display: block;
        min-width: 150px;
        width: 100%;
    }

    .texture-facing {
        display: block;
        color: white;
        text-align: center;
    }

    .texture.circle:not(.loading) {
        -webkit-mask-image: url('./images/circle_mask.svg');
        -webkit-mask-size: 100% 100%;
    }

    .texture.blood:not(.loading) {
        -webkit-mask-image: url('./images/blood_mask.svg');
        -webkit-mask-size: 100% 100%;
    }

    .texture.cognition:not(.loading) {
        -webkit-mask-image: url('./images/cognition_mask.svg');
        -webkit-mask-size: 100% 100%;
    }

    .texture.s1x1 {
        width: 75px;
        height: 75px;
    }

    .texture.s2x2 {
        width: 150px;
        height: 150px;
    }

    .texture.s3x3 {
        width: 225px;
        height: 225px;
    }

    .texture.s2x3 {
        width: 150px;
        height: 225px;
    }

    .texture.s1x2 {
        width: 75px;
        height: 150px;
    }

    .texture.s1x4 {
        width: 75px;
        height: 300px;
    }

    .texture.cognition {
        width: 225px;
        height: 308px;
    }

    .texture.settlement {
        width: 225px;
        height: 429.5px;
    }

    .token-data {
        float: right;
        padding-top: 5px;
    }

    .token-data-entry {
        margin: 5px 10px;
    }

    .token-data-entry .label {
        color: white;
        width: 150px;
        float: left;
    }

    .token_name {
        width: 380px;
    }

    .token_label {
        color: white;
        width: 150px;
        display: inline-block;
    }

    .token_entry {
        margin: 8px;
    }

    .token_name,
    .token_category {
        width: calc(100% - 165px);
    }

    .token_back,
    .token_size {
        width: 200px;
    }

    .overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .info_box {
        width: 600px;
        height: 300px;
        background-color: black;
        position: relative;
        border: solid 2px white;
    }

    .info_box p {
        color: white;
        text-align: center;
    }

    .info {
        font-size: 1.1em;
        padding: 0px 15px;
    }

    .percentage {
        position: absolute;
        display: inline-block;
        bottom: 30px;
        font-size: 0.75em;
        width: 100%;
    }

    .loading_bar {
        height: 20px;
        margin: 0px 15px;
        position: absolute;
        display: inline-block;
        width: 570px;
        bottom: 15px;
        border: 2px solid white;
    }

    .loading_bar .progress {
        background-color: white;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        margin-left: 2px;
        margin-top: 2px;
    }

    #create_token,
    #token-list,
    .token_info,
    .token_list_container {
        font-family: Raleway;
    }

    .swap_texture_button {
        position: absolute;
        top: 35px;
        width: 30px;
        height: 30px;
        right: 0px;
        background-image: url('./images/swap.png');
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        z-index: 1;
        cursor: pointer;
    }
}
