.noStyleButton {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-align: left;
    padding-left: 1px;
    z-index: 1001;
    width: 100%;
    &.checked{
        outline: 1px black solid !important;
    }
    
}

.bold{
    span{

        outline: solid 3px black;
        outline-offset: -2.5px;
    }
}

.generalContainer{
    position: relative;
    .border{
        position: absolute;
        top:1px;
        left:0px;
        width: calc(100% - 4px);
        height: calc(100% - 2px);
        outline: 1px lightgray solid;
        transition: cubic-bezier(0.895, 0.03, 0.685, 0.22) 200ms;
        &.checked{
            outline: 1px black solid;
        }
        &.isLast{
            width: 100%;
        }
    }
}
