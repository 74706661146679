.deck-directory-container{
    .toolbar {
        background-color: gray;
        width: 900px;
        height: 30px;
        float: right;
        margin-left: 20px;    
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.6);
    }
    
    .toolbar_divider {
        border-left: 1px solid #fff;
        height: 22px;    
        margin: 4px 4px 4px 4px;
    }
    
    .toolbar_divider.left {
        float: left;
    }
    
    .toolbar_divider.right {
        float: right;
    }
    
    .save_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/save.png");
        background-size: contain;
        background-color: transparent;
        border: 0;
    }
    
    .save_button:focus {
        outline: none !important;
        border: none;
    }
    
    .save_button:disabled {
        opacity: 0.5;
    }
    
    .refresh_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/refresh.png");
        background-color: transparent;
        background-size: contain;
        border: 0;    
    }
    
    .refresh_button:focus {
        outline: none !important;
        border: none;
    }
    
    .delete_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/delete.png");
        background-color: transparent;
        background-size: contain;
        border: 0;    
    }
    
    .delete_button:focus {
        outline: none !important;
        border: none;
    }
    
    #deck-list {
        padding-top: 40px;
        width: 200px;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.8);
    }
    
    #deck_pagination {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .deck_pagination a, .deck_pagination span {
      color: white;
      float: left;  
      text-decoration: none;  
      margin: 0px 3px 0px 3px;
    }
    
    .deck_pagination a.active {  
      color: cyan;
    }
    
    select.deck_list {    
        width: inherit;    
        background-color: inherit;
        box-shadow: inherit;
        color: white;
        border-width: 0;
        padding: 0.4em;
        scrollbar-width: none;
        overflow: hidden;
        height: 690px;
    }
    
    select.deck_list:focus {
        outline: none !important;
        border: none;
    }
    
    input[type="file"] {
        display: none;
    }
    
    .card_list_container {
        width: 900px;
        float: right;
    }
    
    .card_list {        
        width: 900px;
        height: 100%;    
        float: right;
        margin-left: 20px;    
        text-align: left;
        position: relative;
    }
    
    .new_card {
        float: right;
        width: 150px;
        height: 30px;
        color: black;
    }
    
    .deck_info {
        margin-bottom: 15px;
        padding-bottom: 5px;
        width: 900px;
        height: 100%;    
        float: right;
        margin-left: 20px;    
        text-align: left;
        position: relative;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.5);
    }
    
    #create_deck {
        margin-top: 2px;    
        margin-bottom: 12px;
        float: left;
        width: 200px;
        margin-left: 2px;
    }
    
    #create_deck_name {
        margin-top: 2px;
        float: left;
        width: 168px;
        margin-left: 2px;
    }
    
    #confirm_deck_creation {
        height: 26px;
        margin-top: 2px;
        margin-left: 4px;
        width: 26px;
        float: left;
        background-image: url("./images/import-deck.png");
        background-size: contain;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
    
    #confirm_deck_creation.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;	
    }
    
    .card_entry {
        height: fit-content;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.5);
        margin-bottom: 10px;
        position: relative;
    }
    
    .template {
        display: none;
    }
    
    .card_textures {
        display: inline-block;	
        margin-bottom: 5px;
    }
    
    .remove_card_button {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    
    .texture-entry {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .texture {
        width: 150px;
        height: 231.5px;
        background-color: black;
        display: block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    
    .texture.missing {
        background-image: url("./images/missing.svg") !important;
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .texture.loading {
        display: inline-block;
          position: relative;
    }
    
    .texture.loading  div {
        position: absolute;
        top: 44%;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    
    .texture.loading div:nth-child(1) {
          left: calc(23% + 8px);
          animation: lds-ellipsis1 0.6s infinite;
    }
    
    .texture.loading div:nth-child(2) {
        left: calc(23% + 8px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .texture.loading div:nth-child(3) {
        left: calc(23% + 32px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .texture.loading div:nth-child(4) {
        left: calc(23% + 56px);
        animation: lds-ellipsis3 0.6s infinite;
    }
    
    @keyframes lds-ellipsis1 {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
    }
    
    @keyframes lds-ellipsis3 {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(0);
          }
    }
    
    @keyframes lds-ellipsis2 {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(24px, 0);
          }
    }
    
    .texture-selection {
        display: block;
        width: 150px;
    }
    
    .texture-facing {
        display: block;
        color: white;
        text-align: center;
    }
    
    .texture.small {	
        height: 150px;
        -webkit-mask-image: url("./images/card_mask_small.svg");
        -webkit-mask-size: cover;
    }
    
    .texture.medium, .texture.large, .texture.encounter {
        height: 231.5px;
        -webkit-mask-image: url("./images/card_mask_large.svg");
        -webkit-mask-size: cover;
    }
    
    .texture.knowledge {
        height: 245.8px;
        -webkit-mask-image: url("./images/card_mask_knowledge.svg");
        -webkit-mask-size: cover;
    }
    
    .card-data {
        float: right;
        padding-top: 5px;
    }
    
    .card-data-entry {
        margin: 5px 10px;
    }
    
    .card-data-entry .label {
        color: white;
        width: 150px;
        float: left;
    }
    
    .card_name {
        width: 380px;
    }
    
    .card_quantity {
        width: 100px;
    }
    
    .deck_label {
        color: white;
        width: 150px;
        display: inline-block;
    }
    
    .deck_entry {
        margin: 8px;
    }
    
    .deck_name, .deck_category {
        width: calc(100% - 165px);
    }
    
    .deck_back, .deck_size {
        width: 200px;
    }
    
    .overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: rgba(0,0,0,0.8);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;   
    }
    
    .info_box {
        width: 600px;
        height: 300px;
        background-color: black;
        position: relative;
        border: solid 2px white;
    }
    
    .info_box p {
        color: white;
        text-align: center;
    }
    
    .info {	
        font-size: 1.1em;
        padding: 0px 15px;	
    }
    
    .percentage {
        position: absolute;
        display: inline-block;
        bottom: 30px;
        font-size: 0.75em;
        width: 100%;
    }
    
    .loading_bar {
        height: 20px;
        margin: 0px 15px;
        position: absolute;
        display: inline-block;
        width: 570px;
        bottom: 15px;
        border: 2px solid white;
    }
    
    .loading_bar .progress {
        background-color: white;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        margin-left: 2px;
        margin-top: 2px;
    }
    
    #create_deck, #deck-list, .deck_info, .card_list_container  {
        font-family: Raleway;
    }
    
    .swap_texture_button {
        position: absolute;
        top: 35px;
        width: 30px;
        height: 30px;
        left: 110px;
        background-image: url("./images/swap.png");
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        z-index: 1;
        cursor: pointer;
    }
}