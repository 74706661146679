.book-directory-container{
    .toolbar {
        background-color: gray;
        width: 900px;
        height: 30px;
        float: right;
        margin-left: 20px;    
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.6);
    }
    
    .toolbar_divider {
        border-left: 1px solid #fff;
        height: 22px;    
        margin: 4px 4px 4px 4px;
    }
    
    .toolbar_divider.left {
        float: left;
    }
    
    .toolbar_divider.right {
        float: right;
    }
    
    .save_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/save.png");
        background-size: contain;
        background-color: transparent;
        border: 0;
    }
    
    .save_button:focus {
        outline: none !important;
        border: none;
    }
    
    .save_button:disabled {
        opacity: 0.5;
    }
    
    .refresh_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/refresh.png");
        background-color: transparent;
        background-size: contain;
        border: 0;    
    }
    
    .refresh_button:focus {
        outline: none !important;
        border: none;
    }
    
    .delete_button {
        float: left;
        height: 26px;
        width: 26px;
        margin: 2px;
        background-image: url("./images/delete.png");
        background-color: transparent;
        background-size: contain;
        border: 0;    
    }
    
    .delete_button:focus {
        outline: none !important;
        border: none;
    }
    
    .swap_texture_button {
        position: absolute;
        top: 140px;
        width: 30px;
        height: 30px;
        background-image: url("./images/swap.png");
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        z-index: 1;
        cursor: pointer;
    }
    
    .swap_texture_button.right {
        right: 7px;
        left : auto;
    }
    
    .swap_texture_button.left {
        left: 7px;
    }
    
    .swap_texture_button.back {
        right: 0px;
        left : auto;
    }
    
    .swap_texture_button.front {
        left: 7px;
    }
    
    #book-list {
        padding-top: 40px;
        width: 200px;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.8);
    }
    
    #book_pagination {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .book_pagination a, .book_pagination span {
      color: white;
      float: left;  
      text-decoration: none;  
      margin: 0px 3px 0px 3px;
    }
    
    .book_pagination a.active {  
      color: cyan;
    }
    
    select.book_list {    
        width: inherit;    
        background-color: inherit;
        box-shadow: inherit;
        color: white;
        border-width: 0;
        padding: 0.4em;
        scrollbar-width: none;
        overflow: hidden;
        height: 690px;    
    }
    
    select.book_list:focus {
        outline: none !important;
        border: none;
    }
    
    input[type="file"] {
        display: none;
    }
    
    .book_list_container {
        width: 900px;
        float: right;
    }
    
    .book_display {        
        width: 900px;
        height: 220px;   
        float: right;
        margin-left: 20px;    
        text-align: left;
        position: relative;
        display: flex;
        justify-content: center;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.5);    
    }
    
    .book_display.large .cover-entry {
        margin-right: 12px;
    }
    
    
    .book_display.large .book_texture_entry {
        background-image: url("./images/book-large.svg");
        width: 448px;
        height: 175px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
    
    .book_display.small .cover-entry {
        height: 175px;
    }
    
    .cover-entry {
        position: relative;
    }
    
    .book_display.small .book_texture_entry {
        background-image: url("./images/booklet.svg");
        width: 213px;
        height: 175px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 40px;
        margin-right: 30px;
    }
    
    .book_display.small .texture-entry {
        width: 103px;
        margin-left: 4px;
        margin-right: -2px;
    }
    
    .book_display.large .texture-entry {
        width: 219px;
        margin-left: 4px;
        margin-right: -7px;
    }
    
    .book_display.large .texture {
        height: 167px;
        background-size: 219px 167px;
        background-clip: content-box;
        background-position: center;
        margin-top: 4px;
        margin-bottom: 5px;
    }
    
    .book_display.small .texture {
        height: 172px;
        background-size: 103px 172px;
        margin-top: 2px;
        margin-bottom: 5px;
        margin-left: -2px;
    }
    
    .book_info {
        margin-bottom: 15px;
        padding-bottom: 5px;
        width: 900px;
        height: 100%;    
        float: right;
        margin-left: 20px;    
        text-align: left;
        position: relative;
        box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.5);
    }
    
    #create_book {
        margin-top: 2px;    
        margin-bottom: 12px;
        float: left;
        width: 200px;
        margin-left: 2px;
    }
    
    #create_book_name {
        margin-top: 2px;
        float: left;
        width: 168px;
        margin-left: 2px;
    }
    
    #confirm_book_creation {
        height: 26px;
        margin-top: 2px;
        margin-left: 4px;
        width: 26px;
        float: left;
        background-image: url("./images/import-book.png");
        background-size: contain;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
    
    #confirm_book_creation.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;	
    }
    
    .book_texture_entry {
        margin-bottom: 10px;
        position: relative;
        display: inline-flex;
    }
    
    .book_textures {
        display: inline-block;	
        margin-bottom: 5px;
        &:after{
                clear: both;
                content: "";
                display: block;
            
        }
    }
    
    .texture-entry {
        display: inline-block;
        position :static;
    }
    
    .texture {	
        background-color: transparent;
        display: block;	
        background-repeat: no-repeat;
        cursor: -moz-zoom-in; 
        cursor: -webkit-zoom-in; 
        cursor: zoom-in;
        width: auto;
    }
    
    .zoom-texture {
        background-color: transparent;
        display: block;	
        background-repeat: no-repeat;
        cursor: -moz-zoom-out; 
        cursor: -webkit-zoom-out; 
        cursor: zoom-out;    
        background-size: 100% 100%;
    }
    
    .zoom-texture.large {
        width: 800px;
        height: 610px;
    }
    
    .zoom-texture.small {
        width: 479px;
        height: 800px;
    }
    
    .texture.missing {
        background-image: url("./images/missing.svg") !important;
        background-size: 40px 40px !important;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .texture.loading, .zoom-texture.loading {
        background-color: transparent;
          position: relative;
    }
    
    .texture.loading  div {
        position: absolute;
        top: calc(50% - 6.5px);
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: cyan;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    
    .texture.loading div:nth-child(1) {
          left: calc(50% - 38px + 8px);
          animation: lds-ellipsis1 0.6s infinite;
    }
    
    .texture.loading div:nth-child(2) {
        left: calc(50% - 38px + 8px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .texture.loading div:nth-child(3) {
        left: calc(50% - 38px + 32px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .texture.loading div:nth-child(4) {
        left: calc(50% - 38px + 56px);
        animation: lds-ellipsis3 0.6s infinite;
    }
    
    .zoom-texture.loading  div {
        position: absolute;
        top: calc(50% - 6.5px);
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: cyan;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    
    .zoom-texture.loading div:nth-child(1) {
          left: calc(50% - 38px + 8px);
          animation: lds-ellipsis1 0.6s infinite;
    }
    
    .zoom-texture.loading div:nth-child(2) {
        left: calc(50% - 38px + 8px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .zoom-texture.loading div:nth-child(3) {
        left: calc(50% - 38px + 32px);
        animation: lds-ellipsis2 0.6s infinite;
    }
    
    .zoom-texture.loading div:nth-child(4) {
        left: calc(50% - 38px + 56px);
        animation: lds-ellipsis3 0.6s infinite;
    }
    
    @keyframes lds-ellipsis1 {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
    }
    
    @keyframes lds-ellipsis3 {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(0);
          }
    }
    
    @keyframes lds-ellipsis2 {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(24px, 0);
          }
    }
    
    .texture-selection {
        display: block;
        width: 100%;
        min-width: 100%;
    }
    
    .texture-facing {
        display: block;
        color: white;
        text-align: center;
    }
    
    .book-data {
        float: right;
        padding-top: 5px;
    }
    
    .book-data-entry {
        margin: 5px 10px;
    }
    
    .book-data-entry .label {
        color: white;
        width: 150px;
        float: left;
    }
    
    .book_name {
        width: 380px;
    }
    
    .book_label {
        color: white;
        width: 150px;
        display: inline-block;
    }
    
    .book_entry {
        margin: 8px;
    }
    
    .book_name, .book_category {
        width: calc(100% - 165px);
    }
    
    .book_back, .book_size {
        width: 200px;
    }
    
    .delete-page {
        position: absolute;
        top: 8px;
        width: 15px;
        height: 15px;
        background-image: url("./images/delete-page.svg") !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 0;
        background-color: transparent;
    }
    
    .delete-page.left {
        left: 7px;
    }
    
    .delete-page.right {
        right: 7px;
    }
    
    .toolbar.navigation {
        display: inline-flex;
        justify-content: center;
        margin-top: 10px;
    }
    
    .toolbar.navigation label {
        color: white;
    }
    
    .toolbar.navigation input[type="number"] {
        width: 60px;
        height: 20px
    }
    
    .toolbar.navigation > * {
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .page-jump-container {
        margin-top: 4px;
    }
    
    .page-jump-container input[type="button"] {
        height: 20px;
        float: right;
        line-height: 16px;
        text-align: center;
        font-size: 0.85em;
        margin-left: 5px;
    }
    
    .previous_page {
        margin-top: 3px;
        width: 22px;
        height: 22px;
        background-image: url("./images/left.svg") !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 0;
        background-color: transparent;
    }
    
    .previous_page:focus {
        outline: none !important;
        border: none;
    }
    
    .previous_page:disabled {
        opacity: 0.5;
    }
    
    .next_page:disabled {
        opacity: 0.5;
    }
    
    .next_page {
        margin-top: 3px;
        width: 22px;
        height: 22px;
        background-image: url("./images/right.svg") !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 0;
        background-color: transparent;
    }
    
    .next_page:focus {
        outline: none !important;
        border: none;
    }
    
    .insert-page {
        float: right;
    }
    
    .overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: rgba(0,0,0,0.8);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;   
    }
    
    .info_box {
        width: 600px;
        height: 300px;
        background-color: black;
        position: relative;
        border: solid 2px white;
    }
    
    .info_box p {
        color: white;
        text-align: center;
    }
    
    .info {	
        font-size: 1.1em;
        padding: 0px 15px;	
    }
    
    .percentage {
        position: absolute;
        display: inline-block;
        bottom: 30px;
        font-size: 0.75em;
        width: 100%;
    }
    
    .loading_bar {
        height: 20px;
        margin: 0px 15px;
        position: absolute;
        display: inline-block;
        width: 570px;
        bottom: 15px;
        border: 2px solid white;
    }
    
    .loading_bar .progress {
        background-color: white;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        margin-left: 2px;
        margin-top: 2px;
    }
    
    .no_page > input, .no_page > select {
        visibility: hidden;
    }
    
    .no_page > .texture {
        cursor: default !important;
    }
    
    #create_book, #book-list, .book_info, .book_list_container  {
        font-family: Raleway;
    }
}