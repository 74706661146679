.b-blue-before {
	width: 100px;
	}

.b-blue {
	z-index: 2;
	width: 100%;
	margin: 0px;
	margin-bottom : 0px;
	position: relative;
	top: 0px;
	padding-top: 0px; 
	padding-right: 0px; 
	padding-bottom: 0px; 
	line-height: 0px;
    text-align : center;
	height: 36px;
	font-size: 16px;
	font-weight: bold;
	box-shadow: inset 0 0 0 3000px rgba(0,0,0,0.5);
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.b-blue a,
.b-blue a:visited {
	z-index: 2;
	position:relative;
	margin-top: 9px;	
	padding-top: 8px; 
	padding-bottom: 10px; 
	padding-left: 20px; 
	text-decoration: none;
    color: #AEA59F;
	outline:none;
}

.b-blue a:hover {
	z-index: 2;
	color: #fff;
	text-decoration: none;
}

.b-blue a.selected, 
.b-blue a.selected:visited {
	z-index: 2;
	text-decoration: none; 
	color: #fff;
	font-weight: 900;
}

.b-blue a.selected:hover {
	z-index: 2;
	text-decoration: none; 
	color: #fff;
	font-weight: 900;
}

.b-blue a.selected.logo,
.b-blue a.logo:visited,
.b-blue a.logo,
.b-blue a.selected.logo:visited,
.b-blue a.selected.logo:hover,
.b-blue a.logo:hover {
	z-index: 2;
    background : none;
    position : relative;
    top : 0px;
}

.b-blue form 
{
    margin-top: 12px;
	width: 450px;
	float: right;
}

.b-blue input
{
	margin-right: 5px;
	height: 18px;
	background-color: #000;
	top: -4px;
	font-size: 10px;
	color: #fff !important;
	width: 120px;
	text-align: left;
	outline:none;
	border-color: #AEA59F;
	-webkit-appearance: none; 
    	-moz-appearance: none;
	border: 1px solid #AEA59F;
	padding-left: 4.2px;
}

.b-blue input[type="submit"] 
{
	background: #000 !important;
    height: 18px;
    font-size: 10px;
	text-align: center;
    line-height: 0px;
	color: #fff !important;
	width:50px;
	outline:none;
	border: 1px solid #AEA59F;
	padding: 6px 6px 6px 4.2px;
}

.b-blue input:-webkit-autofill {
	box-shadow: 0 0 0px 1000px black inset;
	-webkit-box-shadow: 0 0 0px 1000px black inset;
	color: #fff !important;
	-webkit-text-fill-color: #fff;
}

.b-blue input:-webkit-autofill:focus {
	box-shadow: 0 0 0px 1000px black inset;
	-webkit-box-shadow: 0 0 0px 1000px black inset;
	color: #fff !important;
	-webkit-text-fill-color: #fff;
}

.b-blue ::-webkit-input-placeholder { 
    color: #AEA59F !important;
}
.b-blue :-moz-placeholder { 
   color: #AEA59F !important;
   opacity:  1;
}
.b-blue ::-moz-placeholder { 
   color: #AEA59F !important;
   opacity:  1;
}
.b-blue :-ms-input-placeholder { 
   color: #AEA59F !important;
}
.b-blue ::-ms-input-placeholder { 
   color: #AEA59F !important;
}

.b-blue ::placeholder { 
   color: #AEA59F !important;
}

#register, #forgot {
	font-size: 0.7em;
	margin: 0px;
	padding-top: 5px;
}

.home-button {
	float: left;
	border: none;
    outline: none;
	color: #fff;
    padding: 10px 16px;
    margin-left: 3px;
    background-color: inherit;
    background-image: url(../../assets/images/lantern.svg);	
    height: 100%;
    top: -7px;
    background-size: contain;
    background-repeat: no-repeat;
}

.navbar {
	position: relative;
	float: right;	
    top : 2px;	
	text-align: right;
	display:block;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.navbar a {

    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 10px 16px;
    text-decoration: none;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.dropdown {
    position: relative;
   	float: right;
    right: 0px;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.dropdown .dropbtn {
	font-size: 14px;    
	border: none;
	outline: none;
	color: #fff;
	padding: 10px 16px;
	background-color: inherit;
	background-image: url(../../assets/images/settings.svg);	
	width: 28px;
	height: 28px;
	background-size: contain;
	background-repeat: no-repeat;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
	pointer-events: none;
}

.dropdown-container {
	display: none;
	width: max-content;
    position: absolute;
    top: 0px;
    z-index: 1;
	right: 5px;
}

.dropdown-content {
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);	
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
    background-color: rgba(0,0,0,0.8);
	padding-right:15px;
	padding-bottom:15px;
	border: 1px solid #fff;	
	margin-top: 30px;
}

.dropdown-content a {
	float: none;
	color: #AEA59F;
	padding: 4px 16px !important;
	text-decoration: none;
    display: block;
	text-align: right;
	left: 15px;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
	font-size: 10px;
}

.dropdown-content a:hover {
	color: #fff;
}

.dropdown:hover .dropdown-container {
	display: block;
}

.icon {
	background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 0px;
    margin-bottom: -8px;
    margin-left: 5px;
    background-size: 90%;
}

.icon.launcher {
	background-image: url(../../assets/images/launcher.png);	
}

.icon.logout {
	background-image: url(../../assets/images/logout.png);	
}

.icon.reset {
	background-image: url(../../assets/images/reset.png);
}

.icon.admin {
	background-image: url(../../assets/images/admin.png);
}

.icon.server {
	background-image: url(../../assets/images/server.png);
}

.icon.store {
	background-image: url(../../assets/images/storeicon.png);
}

.icon.motd {
	background-image: url(../../assets/images/newspaperIcon.png);
}

.icon.bug {
	background-image: url(../../assets/images/bugIcon.png);
}

.icon.key {
	background-image: url(../../assets/images/key.png);
}

.b-blue td {
    background-color:transparent !important;
}

.ribbon-menu {
	display: inline-flex;
}

.ribbon-menu .item {
	top: -6px;
	padding-left: 0px !important;
	padding-right: 70px;
}

.ribbon-menu .icon {
	width: 36px;
	height: 36px;
	display: inline-block;
	background-repeat: no-repeat;
}

.ribbon-menu .current > a {
	color: cyan !important;
}

.ribbon-menu .current > div {
	filter: invert(.25) sepia(3) saturate(20) hue-rotate(135deg);
}

.icon.records {
	background-image: url(../../assets/images/records.svg);
}

.icon.decks {
	background-image: url(../../assets/images/cards.svg);
}

.icon.tokens {
	background-image: url(../../assets/images/tokens.svg);
}

.icon.books {
	background-image: url(../../assets/images/books.svg);
}